import React, { useState, useEffect } from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Box,
  Snackbar,
  Alert,
  Paper,
  Container,
} from '@mui/material';
import Loading from "../../components/Loading";
import { ProfileHeader, BasicInfoCard, PersonalDetailsCard } from './components/ProfileComponents';
import { timezones, gender } from './utils/profileConstants';

const ProfileSection = ({ profile: initialProfile, accessToken, onRefresh }) => {
  const { user } = useAuth0();
  const [profile, setProfile] = useState(initialProfile);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState(initialProfile);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setProfile(initialProfile);
    setEditedProfile(initialProfile);
  }, [initialProfile]);

  const validateProfile = (profile) => {
    const errors = {};
    if (!profile.name) errors.name = 'Name cannot be empty';
    const phoneRegex = /^\+[1-9]\d{1,14}$/;
    if (!phoneRegex.test(profile.number)) errors.number = 'Phone number must be in the format +XXXXXXXXXX';
    if (!timezones.includes(profile.timezone)) errors.timezone = 'Invalid timezone selected';

    const age = Number(profile.age);
    if (!Number.isInteger(age) || age < 13 || age > 120)
      errors.age = 'Age must be between 13 and 120';

    const weight = Number(profile.weight);
    if (!Number.isInteger(weight) || weight < 20 || weight > 300)
      errors.weight = 'Weight must be between 20 and 300 kg';

    if (!profile.gender) errors.gender = 'Please select a gender';
    return errors;
  };

  const handleSave = async () => {
    const validationErrors = validateProfile(editedProfile);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setSnackbar({ open: true, message: Object.values(validationErrors)[0], severity: 'error' });
      return;
    }

    setIsSaving(true);
    try {
      await axios.patch(
        'https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings',
        { profile: editedProfile },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          params: { email: user.email }
        }
      );
      setProfile(editedProfile);
      setIsEditing(false);
      setSnackbar({ 
        open: true, 
        message: 'Profile updated successfully', 
        severity: 'success' 
      });
      if (onRefresh) onRefresh();
    } catch (err) {
      setSnackbar({ 
        open: true, 
        message: 'Failed to update profile', 
        severity: 'error' 
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleChange = (field) => (event) => {
    setEditedProfile({ ...editedProfile, [field]: event.target.value });
    setErrors(prev => ({ ...prev, [field]: '' }));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  if (!profile) return <Loading />;

  return (
    <Container 
      disableGutters 
      sx={{ 
        width: '100%',
        '& .MuiPaper-root': {
          p: { xs: 1.5, sm: 2 }
        },
        '& .MuiCardContent-root': {
          p: { xs: 1.5, sm: 2 },
          '&:last-child': { pb: { xs: 1.5, sm: 2 } }
        }
      }}
    >
      <Paper 
        elevation={0} 
        sx={{ 
          width: '100%',
          margin: 'auto',
        }}
      >
        <ProfileHeader
          profile={profile}
          user={user}
          isEditing={isEditing}
          onEdit={() => setIsEditing(true)}
          onSave={handleSave}
          onCancel={() => {
            setIsEditing(false);
            setEditedProfile(profile);
            setErrors({});
          }}
          isSaving={isSaving}
        />

        <BasicInfoCard
          profile={profile}
          editedProfile={editedProfile}
          handleChange={handleChange}
          errors={errors}
          isEditing={isEditing}
        />

        <PersonalDetailsCard
          profile={profile}
          editedProfile={editedProfile}
          handleChange={handleChange}
          errors={errors}
          isEditing={isEditing}
          timezones={timezones}
          gender={gender}
        />
      </Paper>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

ProfileSection.propTypes = {
  profile: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    number: PropTypes.string,
    age: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gender: PropTypes.string,
    timezone: PropTypes.string,
  }),
  accessToken: PropTypes.string.isRequired,
  onRefresh: PropTypes.func,
};

ProfileSection.defaultProps = {
  profile: {
    name: '',
    email: '',
    number: '',
    age: '',
    weight: '',
    gender: '',
    timezone: 'GMT+00:00',
  },
  onRefresh: () => {},
};

export default withAuthenticationRequired(ProfileSection, {
  onRedirecting: () => <Loading />,
});
