import React from 'react';
import { Box, Stack, Typography, Button } from '@mui/material';
import DailyDurationSlider from './DailyDurationSlider';
import { Zap } from 'lucide-react';

const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const quickPresets = [
  { 
    name: '3 Days',
    values: { Mon: 60, Wed: 60, Fri: 60, Tue: -1, Thu: -1, Sat: -1, Sun: -1 }
  },
  { 
    name: '4 Days',
    values: { Mon: 60, Tue: -1, Wed: 60, Thu: -1, Fri: 60, Sat: 60, Sun: -1 }
  },
  { 
    name: '5 Days',
    values: { Mon: 60, Tue: 60, Wed: 60, Thu: 60, Fri: 60, Sat: -1, Sun: -1 }
  }
];

const TimeBasedSettings = ({ localWorkoutVolume, handleChange, isEditing, errors }) => {
  const applyPreset = (preset) => {
    Object.entries(preset.values).forEach(([day, value]) => {
      handleChange({
        target: {
          name: day,
          value: value // Now passing number directly
        }
      });
    });
  };

  const getValue = (day) => {
    const value = localWorkoutVolume.timeBased?.volumeSelectionTimeBasedSettings?.[day];
    if (value === undefined || value === '') return 0;
    return Number(value); // Convert to number
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Quick Presets
        </Typography>
        <Stack direction="row" spacing={1}>
          {quickPresets.map((preset) => (
            <Button
              key={preset.name}
              variant="outlined"
              size="small"
              startIcon={<Zap size={16} />}
              onClick={() => applyPreset(preset)}
              disabled={!isEditing}
            >
              {preset.name}
            </Button>
          ))}
        </Stack>
      </Box>

      <Typography variant="subtitle1" gutterBottom>
        Daily Training Duration
      </Typography>
      <Stack spacing={2}>
        {daysOfWeek.map((day) => (
          <Box key={day}>
            <DailyDurationSlider
              day={day}
              value={getValue(day)}
              onChange={handleChange}
              disabled={!isEditing}
            />
            {errors[day] && (
              <Typography color="error" variant="caption" sx={{ mt: 0.5, display: 'block' }}>
                {errors[day]}
              </Typography>
            )}
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default TimeBasedSettings;
