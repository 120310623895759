import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery, useTheme, Accordion, AccordionSummary, AccordionDetails, Paper, Container, AppBar, Toolbar } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import TuneIcon from '@mui/icons-material/Tune';
import LinkIcon from '@mui/icons-material/Link';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import SpeedIcon from '@mui/icons-material/Speed';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ProfileSection from './ProfileSection';
import PreferencesSection from './PreferencesSection';
import ConnectionsSection from '../settings2_connections';
import SubscriptionSection from './SubscriptionSection';
import { IntensitySection } from '../settings2_trainingzones';
import { WorkoutPlanningWrapper, WorkoutUpdateStatus } from '../settings2_workoutPlanning';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import axios from 'axios';
import Loading from '../../components/Loading';
import SectionHeader from './DynamicSectionHeader';
import MigrationBanner from './components/MigrationBanner';

const ResponsiveSettings = () => {
    const { getAccessTokenSilently, user } = useAuth0();
    const [profileData, setProfileData] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [workoutPlanningOption, setWorkoutPlanningOption] = useState(null);
    const [showDeleteEventDialog, setShowDeleteEventDialog] = useState(false);
    const [pendingWorkoutOption, setPendingWorkoutOption] = useState(null);
    const theme = useTheme();
    const isWideScreen = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        if (profileData?.event?.name) {
            setWorkoutPlanningOption('event');
        } else {
            setWorkoutPlanningOption('custom');
        }
    }, [profileData]);

    const categories = [
        { name: 'Profile & Preferences', icon: PersonIcon, subCategories: ['Profile', 'Preferences'] },
        { name: 'Subscription', icon: SubscriptionsIcon, component: SubscriptionSection },
        { name: 'Connections', icon: LinkIcon, subCategories: ['Connections'] },
        { name: 'Workout Planning', icon: CalendarMonthIcon },
        { name: 'Training Zones', icon: SpeedIcon, subCategories: ['Intensity'] },
    ];

    const handleWorkoutOptionSelect = (option) => {
        if (option === 'custom' && profileData?.event?.name) {
            setShowDeleteEventDialog(true);
            setPendingWorkoutOption(option);
        } else {
            setWorkoutPlanningOption(option);
        }
    };

    const handleConfirmEventDelete = async () => {
        try {
            await axios.patch(
                'https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings',
                { event: null },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    params: {
                        email: user.email
                    }
                }
            );
            setWorkoutPlanningOption('custom');
            setShowDeleteEventDialog(false);
            setPendingWorkoutOption(null);
            await fetchProfile(accessToken);
        } catch (error) {
            console.error('Error deleting event:', error);
        }
    };

    const handleCloseDeleteDialog = () => {
        setShowDeleteEventDialog(false);
        setPendingWorkoutOption(null);
    };

    const fetchProfile = async (token) => {
        try {
            const response = await axios.get(
                'https://api2.enduroco.in/settings',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    params: {
                        email: user.email
                    }
                }
            );
            setProfileData(response.data);
        } catch (err) {
            setError('Failed to fetch profile data');
            console.error(err);
        }
    };

    useEffect(() => {
        if (!user) return;

        const initializeProfile = async () => {
            try {
                const token = await getAccessTokenSilently();
                setAccessToken(token);
                await fetchProfile(token);
            } catch (err) {
                setError('Failed to initialize profile');
                console.error(err);
            }
        };
        initializeProfile();
    }, [getAccessTokenSilently, user]);

    useEffect(() => {
        if (isWideScreen) {
            setSelectedCategory(0);
        }
    }, [isWideScreen]);

    const checkMigrationStatus = (data) => {
        return data?.profile?.isMigrated === true;
    };
    const getComponentForCategory = (category) => {
        switch (category) {
            case 'Profile':
                return <ProfileSection profile={profileData.profile} accessToken={accessToken} onRefresh={fetchProfile} />;
            case 'Preferences':
                return <PreferencesSection preferences={profileData.preferences} accessToken={accessToken} userEmail={user.email} onRefresh={fetchProfile} />;
            case 'Connections':
                return <ConnectionsSection connections={profileData.connections} accessToken={accessToken} onRefresh={fetchProfile} subscription={profileData.subscription} />;
            case 'Intensity':
                return <IntensitySection workoutIntensity={profileData.workoutIntensity} accessToken={accessToken} userEmail={user.email} onRefresh={fetchProfile} />;
            case 'Subscription':
                return <SubscriptionSection subscription={profileData.subscription} accessToken={accessToken} userEmail={user.email} />;
            default:
                return null;
        }
    };

    const renderSection = (category, subCategory = null) => {
        if (category === 'Workout Planning') {
            return (
                <WorkoutPlanningWrapper
                    workoutPlanningOption={workoutPlanningOption}
                    handleWorkoutOptionSelect={handleWorkoutOptionSelect}
                    profileData={profileData}
                    accessToken={accessToken}
                    userEmail={user.email}
                    fetchProfile={fetchProfile}
                    showDeleteEventDialog={showDeleteEventDialog}
                    handleConfirmEventDelete={handleConfirmEventDelete}
                    handleCloseDeleteDialog={handleCloseDeleteDialog}
                />
            );
        }

        if (subCategory) {
            return getComponentForCategory(subCategory);
        }

        return getComponentForCategory(category);
    };

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (!user || !profileData) {
        return <Loading />;
    }

    const selectedCategoryData = categories[selectedCategory];

    return (
        <Box sx={{
            bgcolor: theme.palette.background.default,
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <AppBar position="sticky" color="primary" elevation={0}>
                <Container maxWidth="lg">
                    <Toolbar>
                        <SettingsIcon sx={{ mr: 2 }} />
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
                            Settings
                        </Typography>
                    </Toolbar>
                </Container>
            </AppBar>
            <Container
                maxWidth="lg"
                sx={{
                    mt: 4,
                    mb: 4,
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {profileData && !checkMigrationStatus(profileData) && (
                    <MigrationBanner
                        accessToken={accessToken}
                        userEmail={user.email}
                        onMigrationComplete={() => fetchProfile(accessToken)}
                    />
                )}

                {profileData?.workoutUpdateStatus && (
                    <WorkoutUpdateStatus workoutUpdateStatus={profileData.workoutUpdateStatus} />
                )}

                {isWideScreen ? (
                    <Paper
                        elevation={3}
                        sx={{
                            display: 'flex',
                            flex: 1,
                            overflow: 'hidden',
                            borderRadius: theme.shape.borderRadius,
                            height: 'calc(100vh - 180px)',
                            position: 'relative'
                        }}
                    >
                        <Box
                            sx={{
                                width: '25%',
                                borderRight: `1px solid ${theme.palette.divider}`,
                                bgcolor: theme.palette.background.paper,
                                overflowY: 'auto'
                            }}
                        >
                            <List component="nav" aria-label="settings categories">
                                {categories.map((category, index) => (
                                    <ListItem
                                        button
                                        key={index}
                                        selected={selectedCategory === index}
                                        onClick={() => setSelectedCategory(index)}
                                        sx={{
                                            borderBottom: `1px solid ${theme.palette.divider}`,
                                            '&.Mui-selected': {
                                                bgcolor: theme.palette.action.selected,
                                                borderLeft: `4px solid ${theme.palette.primary.main}`,
                                            },
                                            '&:hover': {
                                                bgcolor: theme.palette.action.hover,
                                            },
                                        }}
                                    >
                                        <ListItemIcon>
                                            <category.icon color={selectedCategory === index ? 'primary' : 'action'} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <SectionHeader
                                                    categoryName={category.name}
                                                    sectionData={profileData}
                                                />
                                            }
                                            primaryTypographyProps={{
                                                fontWeight: selectedCategory === index ? 'bold' : 'normal',
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        {selectedCategoryData && (
                            <Box
                                sx={{
                                    width: '75%',
                                    p: 3,
                                    overflowY: 'auto',
                                    height: '100%'
                                }}
                            >
                                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                                    {selectedCategoryData.name}
                                </Typography>
                                {selectedCategoryData.subCategories ? (
                                    selectedCategoryData.subCategories.map((subCategory) => (
                                        <Box key={subCategory} sx={{ mb: 4 }}>
                                            {renderSection(selectedCategoryData.name, subCategory)}
                                        </Box>
                                    ))
                                ) : (
                                    renderSection(selectedCategoryData.name)
                                )}
                            </Box>
                        )}
                    </Paper>
                ) : (
                    <Paper
                        elevation={3}
                        sx={{
                            overflow: 'hidden',
                            borderRadius: theme.shape.borderRadius,
                            maxHeight: 'calc(100vh - 180px)',
                            overflowY: 'auto'
                        }}
                    >
                        {categories.map((category, index) => (
                            <Accordion
                                key={index}
                                disableGutters
                                expanded={selectedCategory === index}
                                onChange={() => setSelectedCategory(prevIndex => (prevIndex === index ? -1 : index))}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`${category.name}-content`}
                                    id={`${category.name}-header`}
                                    sx={{
                                        bgcolor: selectedCategory === index ? theme.palette.action.hover : theme.palette.background.paper,
                                        '&.Mui-expanded': {
                                            bgcolor: theme.palette.action.selected,
                                        },
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <category.icon sx={{ mr: 2, color: theme.palette.primary.main }} />
                                        <Typography sx={{ fontWeight: 'bold' }}>{category.name}</Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box>
                                        {category.subCategories ? (
                                            category.subCategories.map((subCategory) => (
                                                <Box key={subCategory} sx={{ mb: 3 }}>
                                                    {renderSection(category.name, subCategory)}
                                                </Box>
                                            ))
                                        ) : (
                                            renderSection(category.name)
                                        )}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Paper>
                )}
            </Container>
        </Box>
    );
};

export default withAuthenticationRequired(ResponsiveSettings, {
    onRedirecting: () => <Loading />,
});
