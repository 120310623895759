import React from 'react';
import {
  Typography,
  Box,
  Button,
  Snackbar,
  Alert,
  Grid,
  Paper
} from '@mui/material';
import { Save, X } from 'lucide-react';
import { useIntensityLogic } from './hooks/useIntensityLogic';
import CyclingMetrics from './components/CyclingMetrics';
import RunningMetrics from './components/RunningMetrics';
import ThresholdInput from './components/ThresholdInput';
import SportCard from './components/layout/SportCard';

const IntensitySection = ({ workoutIntensity, accessToken, userEmail, onRefresh }) => {
  const {
    localWorkoutIntensity,
    setLocalWorkoutIntensity,
    isEditing,
    setIsEditing,
    isSaving,
    snackbar,
    setSnackbar,
    handleSave
  } = useIntensityLogic(workoutIntensity, accessToken, userEmail, onRefresh);

  const handleCyclingMetricsChange = (event, newMetrics) => {
    if (newMetrics?.length === 0) return;

    setLocalWorkoutIntensity(prev => ({
      ...prev,
      cycling: {
        ...prev.cycling,
        metrics: newMetrics,
        ftp: {
          ...prev.cycling.ftp,
          enabled: newMetrics.includes('Power')
        },
        lthr: {
          ...prev.cycling.lthr,
          enabled: newMetrics.includes('HR')
        }
      }
    }));
  };

  const handleRunningMetricChange = (event) => {
    const value = event.target.value;
    if (!['Power', 'HR', 'Pace'].includes(value)) return;

    setLocalWorkoutIntensity(prev => ({
      ...prev,
      running: {
        ...prev.running,
        metrics: [value], // Always set as array
        ftp: {
          ...prev.running.ftp,
          enabled: value === 'Power'
        },
        lthr: {
          ...prev.running.lthr,
          enabled: value === 'HR'
        },
        thresholdPace: {
          ...prev.running.thresholdPace,
          enabled: value === 'Pace'
        }
      }
    }));
  }

  const handleChange = (sport, field, subfield = null) => event => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setLocalWorkoutIntensity(prev => ({
      ...prev,
      [sport]: {
        ...prev[sport],
        [field]: subfield ? { ...prev[sport][field], [subfield]: value } : value
      }
    }));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        elevation={0}
        sx={{
          p: { xs: 2, sm: 3 },
          borderRadius: 2,
          backgroundColor: 'background.default'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
          {isEditing ? (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={isSaving}
                startIcon={<Save size={18} />}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setIsEditing(false)}
                disabled={isSaving}
                startIcon={<X size={18} />}
              >
                Cancel
              </Button>
            </Box>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsEditing(true)}
            >
              Edit Zones
            </Button>
          )}
        </Box>

        <Grid container spacing={2}>
          {localWorkoutIntensity.cycling.enabled && (
            <Grid item xs={12} md={6}>
              <SportCard title="Cycling">
                <CyclingMetrics
                  selectedMetrics={localWorkoutIntensity.cycling.metrics}
                  isEditing={isEditing}
                  onChange={handleCyclingMetricsChange}
                />
                <Box sx={{ mt: 3 }}>
                  <ThresholdInput
                    sport="cycling"
                    metric="ftp"
                    label="Functional Threshold Power (FTP)"
                    min={50}
                    max={500}
                    value={localWorkoutIntensity.cycling.ftp.threshold}
                    onChange={handleChange('cycling', 'ftp', 'threshold')}
                    isEditing={isEditing}
                    enabled={localWorkoutIntensity.cycling.ftp.enabled}
                    unit="watts"
                  />
                  <ThresholdInput
                    sport="cycling"
                    metric="lthr"
                    label="Lactate Threshold Heart Rate (LTHR)"
                    min={60}
                    max={220}
                    value={localWorkoutIntensity.cycling.lthr.threshold}
                    onChange={handleChange('cycling', 'lthr', 'threshold')}
                    isEditing={isEditing}
                    enabled={localWorkoutIntensity.cycling.lthr.enabled}
                    unit="bpm"
                  />
                </Box>
              </SportCard>
            </Grid>
          )}

          {localWorkoutIntensity.running.enabled && (
            <Grid item xs={12} md={6}>
              <SportCard title="Running">
                <RunningMetrics
                  selectedMetric={localWorkoutIntensity.running.metrics} // Pass the whole array
                  isEditing={isEditing}
                  onChange={handleRunningMetricChange}
                />
                <Box sx={{ mt: 3 }}>
                  <ThresholdInput
                    sport="running"
                    metric="ftp"
                    label="Running Power Threshold"
                    min={50}
                    max={500}
                    value={localWorkoutIntensity.running.ftp.threshold}
                    onChange={handleChange('running', 'ftp', 'threshold')}
                    isEditing={isEditing}
                    enabled={localWorkoutIntensity.running.ftp.enabled}
                    unit="watts"
                  />
                  <ThresholdInput
                    sport="running"
                    metric="lthr"
                    label="Lactate Threshold Heart Rate (LTHR)"
                    min={60}
                    max={220}
                    value={localWorkoutIntensity.running.lthr.threshold}
                    onChange={handleChange('running', 'lthr', 'threshold')}
                    isEditing={isEditing}
                    enabled={localWorkoutIntensity.running.lthr.enabled}
                    unit="bpm"
                  />
                  <ThresholdInput
                    sport="running"
                    metric="thresholdPace"
                    label="Threshold Pace"
                    min={2}
                    max={20}
                    value={localWorkoutIntensity.running.thresholdPace.threshold}
                    onChange={handleChange('running', 'thresholdPace', 'threshold')}
                    isEditing={isEditing}
                    enabled={localWorkoutIntensity.running.thresholdPace.enabled}
                    unit="min/km"
                  />
                </Box>
              </SportCard>
            </Grid>
          )}
        </Grid>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default IntensitySection;
