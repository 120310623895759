import React from 'react';
import { Box, Slider, Typography, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { Infinity } from 'lucide-react';

const marks = [
  { value: 30, label: '30m' },
  { value: 60, label: '60m' },
  { value: 90, label: '90m' },
  { value: 120, label: '120m' }
];

const DailyDurationSlider = ({ value, onChange, day, disabled }) => {
  const handleSliderChange = (_, newValue) => {
    onChange({
      target: {
        name: day,
        value: newValue
      }
    });
  };

  const handleTypeChange = (_, newType) => {
    // Prevent deselection
    if (newType === null) return;
    
    onChange({
      target: {
        name: day,
        value: newType === 'nolimit' ? -1 : 0
      }
    });
  };

  const getColor = (value) => {
    if (value === -1) return '#1976d2'; // primary blue for no limit
    if (value === 0) return '#9e9e9e';   // gray for rest
    if (value <= 30) return '#4caf50';
    if (value <= 60) return '#2196f3';
    if (value <= 90) return '#ff9800';
    return '#f44336';
  };

  const isSpecialValue = value === -1 || value === 0;
  const currentMode = value === -1 ? 'nolimit' : value === 0 ? 'rest' : 'duration';

  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center',
      bgcolor: 'background.paper',
      p: 2,
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'divider'
    }}>
      <Box sx={{ minWidth: 100 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
          {day}
        </Typography>
      </Box>
      <Box sx={{ flex: 1, mx: 2 }}>
        <Box sx={{ mb: 2 }}>
          <ToggleButtonGroup
            value={currentMode}
            exclusive
            onChange={(e, newValue) => {
              if (newValue === 'duration') {
                handleSliderChange(e, 60); // Default to 60 minutes when switching to duration
              } else {
                handleTypeChange(e, newValue);
              }
            }}
            size="small"
            disabled={disabled}
            sx={{ width: '100%' }}
          >
            <ToggleButton 
              value="nolimit"
              sx={{ 
                flex: 1,
                '&.Mui-selected': {
                  backgroundColor: `${getColor(-1)}20 !important`,
                  color: `${getColor(-1)} !important`,
                }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Infinity size={16} />
                <span>No limit</span>
              </Box>
            </ToggleButton>
            <ToggleButton 
              value="duration"
              sx={{ 
                flex: 1,
                '&.Mui-selected': {
                  backgroundColor: `${getColor(60)}20 !important`,
                  color: `${getColor(60)} !important`,
                }
              }}
            >
              Duration
            </ToggleButton>
            <ToggleButton 
              value="rest"
              sx={{ 
                flex: 1,
                '&.Mui-selected': {
                  backgroundColor: `${getColor(0)}20 !important`,
                  color: `${getColor(0)} !important`,
                }
              }}
            >
              Rest
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {!isSpecialValue && (
          <Slider
            value={value}
            onChange={handleSliderChange}
            disabled={disabled || isSpecialValue}
            min={15}
            max={120}
            marks={marks}
            step={15}
            valueLabelDisplay="auto"
            valueLabelFormat={v => `${v}m`}
            sx={{
              '& .MuiSlider-thumb': {
                backgroundColor: getColor(value),
              },
              '& .MuiSlider-track': {
                backgroundColor: getColor(value),
              },
              '& .MuiSlider-rail': {
                opacity: 0.3,
              }
            }}
          />
        )}
      </Box>
      <Box sx={{ minWidth: 80, textAlign: 'right' }}>
        <Typography 
          variant="body2" 
          sx={{ 
            color: getColor(value),
            fontWeight: 'medium',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 0.5
          }}
        >
          {value === -1 ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <Infinity size={16} />
              <span>No limit</span>
            </Box>
          ) : value === 0 ? (
            'Rest'
          ) : (
            `${value}m`
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default DailyDurationSlider;
