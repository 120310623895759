import React, { useState, useEffect } from "react";
import { Suspense } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Alert, AlertTitle, Box, Button } from "@mui/material";
import Masonry from "@mui/lab/Masonry";
import PaymentPending from "../paymentPending";
import DataFetcher from "../DataFetcher";
import IntervalsSyncStatus from "../intervalSyncStatus";
import CalendarUpcomingLoader from "../calendarUpcomingLoader";
import Events from "./components/events";
import Loading from "../../components/Loading";
import useSWR from "swr";
import { getConfig } from "../../config";
import LastActivityCard from "./components/lastActivityCard";
import { styled } from "@mui/material/styles";
import FitnessForm from "./components/fitnessForm";
import WorkoutCard from "./components/workoutCard";
import SettingsCard from "./components/settingsCard";
import SettingsNotificationCard from "./components/settingsNotificationCard";
import TrendCard from "./components/trendCard";
import { useFetcher } from "../../utils/fetcher";
import WorkoutTextView from "../calendar/subview/components/workoutTextView";
import HeatMapCard from "./components/heatMapCard";
import FitnessFormChart from "../fitnessFormChart";
import SetupStepperMobile from "../setupStepperMobile";
import MessagesComponent from "./components/messages";

const Item = styled(Box)(({ theme }) => ({}));

function Dashboard({ toggleSettings }) {
  const { user, logout } = useAuth0();
  const [email, setEmail] = useState(user.email);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openWorkoutView, setOpenWorkoutView] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const getColumnCount = () => {
    if (window.innerWidth > 1280) return 3;
    if (window.innerWidth > 700) return 2;
    return 1;
  };

  const [columnCount, setColumnCount] = React.useState(getColumnCount());

  const config = getConfig();
  const profileUrl = `${config.api2Endpoint}/user`;
  const { fetcher, accessToken } = useFetcher();
  const { data, error, mutate } = useSWR(
    accessToken ? profileUrl : null,
    fetcher
  );

  const checkMigrationStatus = (userObject) => {
    return userObject.profile !== undefined;
  };

  const handleMigrate = async () => {
    try {
      const response = await fetch(`${config.api2Endpoint}/settings?email=${email}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          profile: {
            name: user.name
          }
        })
      });

      if (!response.ok) {
        throw new Error('Migration failed');
      }

      // Wait for 10 seconds before refreshing
      setTimeout(() => {
        mutate();
      }, 1000);

    } catch (error) {
      setErrorMessage('Failed to migrate user data. Please try again later.');
    }
  };

  useEffect(() => {
    if (accessToken) {
      mutate();
    }
  }, [accessToken, mutate]);

  useEffect(() => {
    if (error?.response?.status === 401) {
      handleLogout();
    }
  }, [error, logout]);

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  useEffect(() => {
    const handleResize = () => {
      setColumnCount(getColumnCount());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onWorkoutCardClick = (workoutData) => {
    const manipulatedWorkoutData = manipulateWorkoutData(workoutData);
    setSelectedEvent(manipulatedWorkoutData);
    setOpenWorkoutView(true);
  };

  const manipulateWorkoutData = (workoutData) => {
    const manipulatedData = {
      ...workoutData,
      date: workoutData.workoutdate,
      woType: workoutData.woType || workoutData.type,
      completed: false,
      eventtype: workoutData.type,
      load: Math.round(
        workoutData.tss ||
        workoutData.pace_load ||
        workoutData.heart_rate_load ||
        workoutData.training_load
      ),
      loads: {
        tss: workoutData.tss,
        training_load: workoutData.training_load,
        pace_load: workoutData.pace_load,
        heart_rate_load: workoutData.heart_rate_load,
      },
    };
    return manipulatedData;
  };

  const revalidateProfile = () => {
    mutate();
  };

  return (
    <>
      <DataFetcher user={user}>
        {({ dataV2, error }) => {
          if (!dataV2) return <CalendarUpcomingLoader />;
          if (!data) return <CalendarUpcomingLoader />;

          const isMigrated = checkMigrationStatus(data);

          return (
            <div className="calendar_section">
              <div>
                <Item>
                  <PaymentPending
                    email={email}
                    revalidateProfile={revalidateProfile}
                    profile={data}
                    accessToken={accessToken}
                  />

                  {errorMessage && (
                    <Alert severity="error">
                      <AlertTitle>Error</AlertTitle>
                      {errorMessage}
                    </Alert>
                  )}

                  {!isMigrated && (
                    <Alert
                      severity="warning"
                      sx={{ mb: 2 }}
                      action={
                        <Button
                          color="inherit"
                          size="small"
                          onClick={handleMigrate}
                        >
                          Migrate Now
                        </Button>
                      }
                    >
                      <AlertTitle>System Update Required</AlertTitle>
                      To access all new features and improvements, we need to update your account to our new system.
                    </Alert>
                  )}
                </Item>

                <SetupStepperMobile userProfile={data} />

                {isMigrated ? (
                  <Masonry
                    columns={columnCount}
                    spacing={1}
                    sx={{
                      margin: 0,
                      "& .MuiMasonryItem-root": {
                        margin: 0,
                      },
                    }}
                  >
                    <Item>
                      <WorkoutCard
                        dayOffset={0}
                        user={user}
                        data={dataV2}
                        error={error}
                        onClick={(workoutData) => onWorkoutCardClick(workoutData)}
                      />
                    </Item>
                    <Item>
                      <WorkoutCard
                        dayOffset={1}
                        user={user}
                        data={dataV2}
                        error={error}
                        onClick={(workoutData) => onWorkoutCardClick(workoutData)}
                      />
                    </Item>
                    <Item>
                      <LastActivityCard user={user} data={dataV2} error={error} />
                    </Item>
                    <Item>
                      <TrendCard
                        email={email}
                        accessToken={accessToken}
                      />
                    </Item>
                    <Item>
                      <FitnessForm
                        email={email}
                        setErrorMessage={setErrorMessage}
                        data={dataV2.fitness}
                        profile={dataV2.profile}
                        error={error}
                      />
                    </Item>
                    <Item>
                      <MessagesComponent accessToken={accessToken} />
                    </Item>
                    <Item>
                      <SettingsNotificationCard
                        email={email}
                        accessToken={accessToken}
                        toggleSettings={toggleSettings}
                      />
                    </Item>
                    <Item>
                      <HeatMapCard data={dataV2} />
                    </Item>
                  </Masonry>
                ) : (
                  <Box sx={{ p: 2, textAlign: 'center' }}>
                    <Box sx={{ color: 'text.secondary' }}>
                      Please migrate your account to access your dashboard.
                    </Box>
                  </Box>
                )}

                {isMigrated && <FitnessFormChart fitnessData={dataV2} />}
              </div>
            </div>
          );
        }}
      </DataFetcher>
      {selectedEvent && (
        <WorkoutTextView
          open={openWorkoutView}
          setOpen={setOpenWorkoutView}
          woObj={selectedEvent}
          accessToken={accessToken}
        />
      )}
    </>
  );
}

export default withAuthenticationRequired(
  function DashboardWrapper(props) {
    return (
      <Suspense fallback={<Loading />}>
        <Dashboard {...props} />
      </Suspense>
    );
  },
  {
    onRedirecting: () => <Loading />,
  }
);