import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import DaysPerWeekSelector from './DaysPerWeekSelector';
import WeekStartSelector from './WeekStartSelector';
import RampRateSelector from './RampRateSelector';

const ManualVolumeSettings = ({ localWorkoutVolume, handleChange, isEditing, errors }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Training Days
          </Typography>
          <DaysPerWeekSelector
            value={localWorkoutVolume.manual.numberofdaysweekly || ''}
            onChange={(value) => handleChange({
              target: {
                name: 'numberofdaysweekly',
                value
              }
            })}
            disabled={!isEditing}
          />
          {errors.numberofdaysweekly && (
            <Typography color="error" variant="caption" sx={{ mt: 0.5, display: 'block' }}>
              {errors.numberofdaysweekly}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Week Start Day
          </Typography>
          <WeekStartSelector
            value={localWorkoutVolume.manual.weekstart || ''}
            onChange={(value) => handleChange({
              target: {
                name: 'weekstart',
                value
              }
            })}
            disabled={!isEditing}
          />
          {errors.weekstart && (
            <Typography color="error" variant="caption" sx={{ mt: 0.5, display: 'block' }}>
              {errors.weekstart}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ mb: 1 }}>
            <Typography variant="subtitle1" gutterBottom>
              Workout Progression Rate
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Choose how quickly your workout intensity and duration will progress
            </Typography>
            <RampRateSelector
              value={localWorkoutVolume.manual.targetformpct || '0'}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ManualVolumeSettings;
