import React from 'react';
import { Box, Typography, Alert, Radio, FormControlLabel, RadioGroup, Paper } from '@mui/material';
import { Info, Zap, Heart, Timer } from 'lucide-react';

const RunningMetrics = ({ selectedMetric, isEditing, onChange }) => {
  // Ensure selectedMetric is the first element if it's an array
  const currentMetric = Array.isArray(selectedMetric) ? selectedMetric[0] : selectedMetric;

  const getOptionIcon = (value) => {
    switch(value) {
      case 'Power':
        return <Zap size={20} />;
      case 'HR':
        return <Heart size={20} />;
      case 'Pace':
        return <Timer size={20} />;
      default:
        return null;
    }
  };

  const getOptionLabel = (value) => {
    switch(value) {
      case 'Power':
        return 'Power Based Training Only';
      case 'HR':
        return 'Heart Rate Based Training Only';
      case 'Pace':
        return 'Pace Based Training Only';
      default:
        return '';
    }
  };

  return (
    <Box>
      <Typography 
        variant="subtitle1" 
        sx={{ 
          mb: 2,
          fontWeight: 500,
          color: 'text.primary'
        }}
      >
        Training Method
      </Typography>

      <RadioGroup
        value={currentMetric}
        onChange={onChange}
        sx={{ width: '100%' }}
      >
        {['Power', 'HR', 'Pace'].map((value) => (
          <Paper
            key={value}
            elevation={0}
            sx={{
              mb: 1,
              borderRadius: 2,
              border: '2px solid',
              borderColor: value === currentMetric ? 'primary.main' : 'divider',
              backgroundColor: value === currentMetric ? 'primary.main' : 'background.paper',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                borderColor: 'primary.main',
                backgroundColor: value === currentMetric ? 'primary.dark' : 'action.hover',
              }
            }}
          >
            <FormControlLabel
              value={value}
              disabled={!isEditing}
              control={
                <Radio 
                  sx={{
                    color: value === currentMetric ? 'primary.contrastText' : 'text.primary',
                    '&.Mui-checked': {
                      color: 'primary.contrastText',
                    }
                  }}
                />
              }
              label={
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1.5,
                  color: value === currentMetric ? 'primary.contrastText' : 'text.primary',
                }}>
                  {getOptionIcon(value)}
                  <Typography sx={{ 
                    fontWeight: value === currentMetric ? 600 : 400,
                  }}>
                    {getOptionLabel(value)}
                  </Typography>
                </Box>
              }
              sx={{
                mx: 0,
                px: 2,
                py: 1.5,
                width: '100%',
              }}
            />
          </Paper>
        ))}
      </RadioGroup>

      {!isEditing && currentMetric && (
        <Alert 
          severity="info"
          icon={<Info size={20} />}
          sx={{ 
            mt: 2,
            borderRadius: 2,
            '& .MuiAlert-message': {
              fontSize: '0.875rem'
            }
          }}
        >
          Training zones and workouts will be tailored to your {currentMetric === 'Power' ? 'power' : currentMetric === 'HR' ? 'heart rate' : 'pace'}-based training method.
        </Alert>
      )}
    </Box>
  );
};

export default RunningMetrics;
