import React from 'react';
import { Box, Typography, alpha } from '@mui/material';
import { Clock, Ban, Infinity, Sun, Moon, AlertCircle } from 'lucide-react';

const DailyDurationCalendar = ({ settings = {} }) => {
  const days = [
    { key: 'Mon', label: 'Monday', angle: 270 },
    { key: 'Tue', label: 'Tuesday', angle: 315 },
    { key: 'Wed', label: 'Wednesday', angle: 0 },
    { key: 'Thu', label: 'Thursday', angle: 45 },
    { key: 'Fri', label: 'Friday', angle: 90 },
    { key: 'Sat', label: 'Saturday', angle: 135 },
    { key: 'Sun', label: 'Sunday', angle: 180 }
  ];

  const getTimeInfo = (value) => {
    if (value === -1) return { 
      icon: Infinity, 
      label: 'Flexible', 
      color: '#1976d2',
      intensity: 100
    };
    if (value === 0) return { 
      icon: Moon, 
      label: 'Rest', 
      color: '#757575',
      intensity: 0 
    };
    
    let intensity = (value / 120) * 100; // Assuming max is 120 minutes
    if (value <= 30) return { 
      icon: Clock, 
      label: `${value}min`, 
      color: '#4caf50',
      intensity 
    };
    if (value <= 60) return { 
      icon: Clock, 
      label: `${value}min`, 
      color: '#fb8c00',
      intensity
    };
    return { 
      icon: Clock, 
      label: `${value}min`, 
      color: '#e53935',
      intensity
    };
  };

  return (
    <Box sx={{ 
      width: '100%', 
      height: { xs: 'auto', md: '400px' },
      position: 'relative',
      display: { xs: 'block', md: 'flex' },
      justifyContent: 'center',
      alignItems: 'center',
      py: 4
    }}>
      {/* Mobile View */}
      <Box sx={{ 
        display: { xs: 'flex', md: 'none' },
        flexDirection: 'column',
        gap: 1,
        px: 2
      }}>
        {days.map(({ key, label }) => {
          const value = settings[key] || 0;
          const { icon: Icon, label: timeLabel, color, intensity } = getTimeInfo(value);
          
          return (
            <Box
              key={key}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                p: 2,
                borderRadius: '12px',
                bgcolor: alpha(color, 0.1),
                border: '1px solid',
                borderColor: alpha(color, 0.2),
                transition: 'all 0.2s',
                '&:hover': {
                  transform: 'translateX(8px)',
                  bgcolor: alpha(color, 0.15)
                }
              }}
            >
              <Box sx={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: alpha(color, 0.2),
                color: color
              }}>
                <Icon size={20} />
              </Box>
              
              <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  {label}
                </Typography>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  {timeLabel}
                </Typography>
              </Box>

              <Box sx={{
                ml: 'auto',
                width: '60px',
                height: '4px',
                borderRadius: '2px',
                bgcolor: alpha(color, 0.2),
                position: 'relative',
                overflow: 'hidden'
              }}>
                <Box sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  height: '100%',
                  width: `${intensity}%`,
                  bgcolor: color,
                  transition: 'width 0.3s ease-in-out'
                }} />
              </Box>
            </Box>
          );
        })}
      </Box>

      {/* Desktop View - Circular Layout */}
      <Box sx={{ 
        display: { xs: 'none', md: 'block' },
        width: '100%',
        height: '100%',
        position: 'relative'
      }}>
        <Box sx={{
          width: '300px',
          height: '300px',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '50%',
          border: '2px solid',
          borderColor: 'divider',
        }} />

        {days.map(({ key, label, angle }) => {
          const value = settings[key] || 0;
          const { icon: Icon, label: timeLabel, color, intensity } = getTimeInfo(value);
          const radians = (angle - 90) * (Math.PI / 180);
          const radius = 150;
          const x = Math.cos(radians) * radius;
          const y = Math.sin(radians) * radius;
          
          return (
            <Box
              key={key}
              sx={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px))`,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Box sx={{
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                bgcolor: alpha(color, 0.1),
                border: '2px solid',
                borderColor: color,
                transition: 'all 0.2s',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.1)',
                  bgcolor: alpha(color, 0.2)
                }
              }}>
                <Icon size={20} color={color} />
                <Typography variant="caption" sx={{ color: color, fontWeight: 600 }}>
                  {timeLabel}
                </Typography>
              </Box>
              
              <Typography 
                variant="caption" 
                sx={{ 
                  fontWeight: 500,
                  color: 'text.secondary',
                  textAlign: 'center',
                  width: '80px'
                }}
              >
                {label}
              </Typography>
            </Box>
          );
        })}

        {/* Center Info */}
        <Box sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          width: '120px'
        }}>
          <Typography variant="h6" sx={{ color: 'primary.main', fontWeight: 600 }}>
            Weekly Plan
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Training Schedule
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DailyDurationCalendar;
