import React from 'react';
import { Box, Card, CardContent, Typography, Radio, Grid } from '@mui/material';
import { Calendar, Clock } from 'lucide-react';

const VolumeMethodSelector = ({ value, onChange, disabled }) => {
  const methods = [
    {
      id: 'manual',
      title: 'Weekly Schedule',
      description: 'Set number of training days per week',
      icon: Calendar,
      color: '#2196f3'
    },
    {
      id: 'timebased',
      title: 'Daily Duration',
      description: 'Specify training time for each day',
      icon: Clock,
      color: '#4caf50'
    }
  ];

  const handleMethodChange = (methodId) => {
    if (!disabled) {
      onChange(methodId);
    }
  };

  return (
    <Grid container spacing={2}>
      {methods.map((method) => {
        const Icon = method.icon;
        const isSelected = value === method.id;

        return (
          <Grid item xs={12} sm={6} key={method.id}>
            <Card
              sx={{
                cursor: disabled ? 'not-allowed' : 'pointer',
                height: '100%',
                position: 'relative',
                opacity: disabled ? 0.7 : 1,
                transition: 'all 0.2s',
                border: '2px solid',
                borderColor: isSelected ? method.color : 'divider',
                bgcolor: isSelected ? `${method.color}10` : 'background.paper',
                '&:hover': {
                  borderColor: !disabled && method.color,
                  bgcolor: !disabled && `${method.color}10`
                }
              }}
              elevation={0}
              onClick={() => handleMethodChange(method.id)}
            >
              <CardContent sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Radio
                    checked={isSelected}
                    onChange={() => handleMethodChange(method.id)}
                    value={method.id}
                    name="volume-method-radio"
                    disabled={disabled}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: method.color,
                      '&.Mui-checked': {
                        color: method.color
                      }
                    }}
                  />
                  <Icon
                    size={24}
                    style={{
                      marginRight: '12px',
                      color: isSelected ? method.color : 'inherit'
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      color: isSelected ? method.color : 'text.primary',
                      fontWeight: 500
                    }}
                  >
                    {method.title}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  color={isSelected ? method.color : 'text.secondary'}
                  sx={{ pl: 4.5 }}
                >
                  {method.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default VolumeMethodSelector;
