import axios from 'axios';

export const updateWorkoutSettings = async (discipline, workoutVolume, accessToken, userEmail) => {
    // Create a clean workout volume object without trainingBlock
    const cleanWorkoutVolume = {
        volumeSelectionMethod: workoutVolume.volumeSelectionMethod,
        manual: workoutVolume.manual,
        timeBased: workoutVolume.timeBased,
        settingEnabled: true
    };

    const response = await axios.patch(
        'https://mvv9zgjo3h.execute-api.us-east-1.amazonaws.com/settings',
        {
            discipline,
            workoutVolume: cleanWorkoutVolume
        },
        {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            params: { email: userEmail }
        }
    );

    return response.data;
};
