export const DEFAULT_WORKOUT_VOLUME = {
    volumeSelectionMethod: 'manual',
    manual: {},
    timeBased: {
        volumeSelectionTimeBasedSettings: {}
    },
    settingEnabled: true
};

export const validateWorkoutSettings = (workoutVolume) => {
    const errors = {};
    
    if (workoutVolume.volumeSelectionMethod === 'manual') {
        const { numberofdaysweekly, weekstart, targetformpct } = workoutVolume.manual;

        if (!['3', '4', '5', '6'].includes(numberofdaysweekly)) {
            errors.numberofdaysweekly = 'Number of days must be 3, 4, 5, or 6';
        }

        if (!['0', '1', '6'].includes(weekstart)) {
            errors.weekstart = 'Week start must be Sunday (0), Monday (1), or Saturday (6)';
        }

        const targetFormpctNum = Number(targetformpct);
        const allowedPctValues = [-40, -30, -20, -10, 0, 10, 20, 30, 40];
        if (!allowedPctValues.includes(targetFormpctNum)) {
            errors.targetformpct = 'Form percentage must be -40, -30, -20, -10, 0, 10, 20, 30, 40';
        }
    }

    return errors;
};
