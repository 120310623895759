import React, { useState, useEffect } from 'react';
import {
    Typography,
    Box,
    Divider,
    Snackbar,
    Alert,
    Paper,
    Container,
    Card,
    CardContent,
    Grid,
    Chip,
    Button,
} from '@mui/material';
import { Dumbbell, Edit2, Calendar, Clock, TrendingUp, Medal } from 'lucide-react';
import DisciplineSelector from './sectionCustomWorkout/DisciplineSelector';
import VolumeMethodSelector from './components/VolumeMethodSelector';
import ManualVolumeSettings from './components/ManualVolumeSettings';
import TimeBasedSettings from './components/TimeBasedSettings';
import FloatingSaveButton from './components/FloatingSaveButton';
import { DEFAULT_WORKOUT_VOLUME, validateWorkoutSettings } from './WorkoutSettingsTypes';
import { updateWorkoutSettings } from './WorkoutSettingsService';
import { getRampRateInfo, getWeekStartLabel } from './utils/workoutConstants';
import DailyDurationCalendar from './components/DailyDurationCalendar';

const WorkoutSettingsSection = ({
    workoutVolume,
    discipline: initialDiscipline,
    accessToken,
    userEmail,
    onRefresh,
    hasEvent
}) => {
    const [discipline, setDiscipline] = useState(initialDiscipline);
    const [localWorkoutVolume, setLocalWorkoutVolume] = useState(workoutVolume || DEFAULT_WORKOUT_VOLUME);
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [errors, setErrors] = useState({});
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        setLocalWorkoutVolume(workoutVolume || DEFAULT_WORKOUT_VOLUME);
    }, [workoutVolume]);

    useEffect(() => {
        const hasWorkoutVolumeChanged = JSON.stringify(workoutVolume) !== JSON.stringify(localWorkoutVolume);
        const hasDisciplineChanged = initialDiscipline !== discipline;
        setHasChanges(isEditing && (hasWorkoutVolumeChanged || hasDisciplineChanged));
    }, [workoutVolume, localWorkoutVolume, initialDiscipline, discipline, isEditing]);

    const handleVolumeMethodChange = (method) => {
        setLocalWorkoutVolume(prev => ({
            ...prev,
            volumeSelectionMethod: method,
            manual: method === 'manual' ? (prev.manual || {}) : {},
            timeBased: method === 'timebased' ? (prev.timeBased || { volumeSelectionTimeBasedSettings: {} }) : {}
        }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        
        if (localWorkoutVolume.volumeSelectionMethod === 'manual') {
            setLocalWorkoutVolume(prev => ({
                ...prev,
                manual: {
                    ...prev.manual,
                    [name]: value
                }
            }));
        } else if (localWorkoutVolume.volumeSelectionMethod === 'timebased') {
            setLocalWorkoutVolume(prev => ({
                ...prev,
                timeBased: {
                    ...prev.timeBased,
                    volumeSelectionTimeBasedSettings: {
                        ...prev.timeBased.volumeSelectionTimeBasedSettings,
                        [name]: value
                    }
                }
            }));
        }
    };

    const handleSave = async () => {
        try {
            setIsSaving(true);
            const validationErrors = validateWorkoutSettings(localWorkoutVolume);
            
            if (Object.keys(validationErrors).length > 0) {
                setErrors(validationErrors);
                throw new Error('Validation failed');
            }

            await updateWorkoutSettings(discipline, localWorkoutVolume, accessToken, userEmail);
            
            setSnackbar({
                open: true,
                message: 'Settings saved successfully',
                severity: 'success'
            });
            
            if (onRefresh) {
                await onRefresh();
            }
            
            setIsEditing(false);
            setErrors({});
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Error saving settings: ' + (error.message || 'Unknown error'),
                severity: 'error'
            });
        } finally {
            setIsSaving(false);
        }
    };

    const getDisciplineIcon = (disciplineType) => {
        switch (disciplineType?.toLowerCase()) {
            case 'running':
                return '🏃';
            case 'cycling':
                return '🚴';
            case 'triathlon':
                return '🏊';
            case 'duathlon':
                return '🏃🚴';
            default:
                return '🎯';
        }
    };

    const getDisciplineColor = (disciplineType) => {
        switch (disciplineType?.toLowerCase()) {
            case 'running':
                return '#2196f3';
            case 'cycling':
                return '#4caf50';
            case 'triathlon':
                return '#ff9800';
            case 'duathlon':
                return '#9c27b0';
            default:
                return '#757575';
        }
    };

    const getDisciplineDescription = (disciplineType) => {
        switch (disciplineType?.toLowerCase()) {
            case 'running':
                return 'Road & Trail Running';
            case 'cycling':
                return 'Road & Indoor Cycling';
            case 'triathlon':
                return 'Swim, Bike & Run';
            case 'duathlon':
                return 'Run, Bike & Run';
            default:
                return 'Not Set';
        }
    };

    const renderTimeBasedSummary = () => {
        const settings = localWorkoutVolume.timeBased?.volumeSelectionTimeBasedSettings || {};
        return <DailyDurationCalendar settings={settings} />;
    };

    const renderSettingsView = () => {
        const rampRate = localWorkoutVolume.manual?.targetformpct;
        const rampRateInfo = getRampRateInfo(rampRate);

        return (
            <Box sx={{ mt: 2 }}>
                <Card variant="outlined" sx={{ mb: 3 }}>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Box sx={{
                                    bgcolor: 'background.paper',
                                    p: 2,
                                    borderRadius: 1,
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    mb: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2
                                }}>
                                    <Box sx={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: '50%',
                                        bgcolor: `${getDisciplineColor(initialDiscipline)}15`,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '1.5rem'
                                    }}>
                                        {getDisciplineIcon(initialDiscipline)}
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                            Selected Discipline
                                        </Typography>
                                        <Typography variant="body1" sx={{ 
                                            color: getDisciplineColor(initialDiscipline),
                                            fontWeight: 500
                                        }}>
                                            {initialDiscipline || 'Not Set'}
                                        </Typography>
                                        <Typography variant="caption" color="text.secondary">
                                            {getDisciplineDescription(initialDiscipline)}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box sx={{
                                    bgcolor: 'background.paper',
                                    p: 2,
                                    borderRadius: 1,
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    mb: 2
                                }}>
                                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                        Training Approach
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        {localWorkoutVolume.volumeSelectionMethod === 'manual' ? (
                                            <Calendar size={20} />
                                        ) : (
                                            <Clock size={20} />
                                        )}
                                        <Typography variant="body1">
                                            {localWorkoutVolume.volumeSelectionMethod === 'manual'
                                                ? 'Weekly Schedule'
                                                : 'Daily Duration'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            {localWorkoutVolume.volumeSelectionMethod === 'manual' ? (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{
                                            bgcolor: 'background.paper',
                                            p: 2,
                                            borderRadius: 1,
                                            border: '1px solid',
                                            borderColor: 'divider'
                                        }}>
                                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                                Training Days
                                            </Typography>
                                            <Chip
                                                label={`${localWorkoutVolume.manual.numberofdaysweekly || '0'} days per week`}
                                                color="primary"
                                                size="small"
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{
                                            bgcolor: 'background.paper',
                                            p: 2,
                                            borderRadius: 1,
                                            border: '1px solid',
                                            borderColor: 'divider'
                                        }}>
                                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                                Week Starts On
                                            </Typography>
                                            <Chip
                                                label={getWeekStartLabel(localWorkoutVolume.manual.weekstart)}
                                                color="primary"
                                                size="small"
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box sx={{
                                            bgcolor: `${rampRateInfo.color}10`,
                                            p: 2,
                                            borderRadius: 1,
                                            border: '1px solid',
                                            borderColor: rampRateInfo.color
                                        }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                                                <TrendingUp size={20} style={{ color: rampRateInfo.color }} />
                                                <Typography variant="subtitle2" sx={{ color: rampRateInfo.color }}>
                                                    {rampRateInfo.label}
                                                </Typography>
                                            </Box>
                                            <Typography variant="body2" color="text.secondary">
                                                {rampRateInfo.description}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={12}>
                                    {renderTimeBasedSummary()}
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        );
    };

    return (
        <Container maxWidth="md" sx={{ p: { xs: 0, sm: 1 } }}>
            <Paper elevation={0} sx={{ p: { xs: 2, sm: 3 }, margin: 'auto', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Dumbbell size={32} style={{ marginRight: '12px', color: '#1976d2' }} />
                        <Typography variant="h5" component="h1">
                            Custom Training Settings
                        </Typography>
                    </Box>
                    {!hasEvent && (
                        <Button
                            variant={isEditing ? "outlined" : "contained"}
                            color={isEditing ? "secondary" : "primary"}
                            onClick={() => setIsEditing(!isEditing)}
                            startIcon={<Edit2 size={16} />}
                        >
                            {isEditing ? "Cancel" : "Edit Settings"}
                        </Button>
                    )}
                </Box>
                <Divider sx={{ mb: 3 }} />

                {isEditing ? (
                    <>
                        <Box sx={{ mb: 4 }}>
                            <DisciplineSelector
                                selectedDiscipline={discipline}
                                onChange={(newDiscipline) => setDiscipline(newDiscipline)}
                                disabled={hasEvent}
                            />
                        </Box>

                        <Box sx={{ mt: 4 }}>
                            <Typography variant="h6" gutterBottom>
                                Training Volume Method
                            </Typography>
                            <VolumeMethodSelector
                                value={localWorkoutVolume.volumeSelectionMethod}
                                onChange={handleVolumeMethodChange}
                                disabled={hasEvent}
                            />

                            {localWorkoutVolume.volumeSelectionMethod === 'manual' && (
                                <ManualVolumeSettings
                                    localWorkoutVolume={localWorkoutVolume}
                                    handleChange={handleChange}
                                    isEditing={true}
                                    errors={errors}
                                />
                            )}
                            {localWorkoutVolume.volumeSelectionMethod === 'timebased' && (
                                <TimeBasedSettings
                                    localWorkoutVolume={localWorkoutVolume}
                                    handleChange={handleChange}
                                    isEditing={true}
                                    errors={errors}
                                />
                            )}
                        </Box>
                    </>
                ) : (
                    renderSettingsView()
                )}
            </Paper>

            <FloatingSaveButton
                onSave={handleSave}
                isSaving={isSaving}
                hasChanges={hasChanges}
            />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
            >
                <Alert
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default WorkoutSettingsSection;
