import React from 'react';
import { Paper, Typography, Box, Collapse } from '@mui/material';
import { Bike, Activity } from 'lucide-react';

const SportCard = ({ title, children, expanded = true }) => {
  const icon = title.toLowerCase() === 'cycling' ? (
    <Bike size={24} style={{ color: '#1976d2' }} />
  ) : (
    <Activity size={24} style={{ color: '#1976d2' }} />
  );

  return (
    <Paper
      elevation={1}
      sx={{
        p: { xs: 2, sm: 3 },
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.paper',
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          boxShadow: 3,
          borderColor: 'primary.main',
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        {icon}
        <Typography variant="h6" sx={{ ml: 1.5, fontWeight: 600 }}>
          {title}
        </Typography>
      </Box>
      <Collapse in={expanded}>
        <Box>
          {children}
        </Box>
      </Collapse>
    </Paper>
  );
};

export default SportCard;
