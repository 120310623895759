import React from 'react';
import { Box, Typography, Alert, Radio, FormControlLabel, RadioGroup, Paper } from '@mui/material';
import { Info, Zap, Heart } from 'lucide-react';

const CyclingMetrics = ({ selectedMetrics, isEditing, onChange }) => {
  // Convert between radio value and metrics array
  const radioValue = Array.isArray(selectedMetrics) ? 
    (selectedMetrics.includes('Power') && selectedMetrics.includes('HR') ? 'Both' : selectedMetrics[0]) 
    : '';

  const handleRadioChange = (event) => {
    const value = event.target.value;
    let newMetrics;
    switch(value) {
      case 'Power':
        newMetrics = ['Power'];
        break;
      case 'HR':
        newMetrics = ['HR'];
        break;
      case 'Both':
        newMetrics = ['Power', 'HR'];
        break;
      default:
        newMetrics = [];
    }
    onChange(null, newMetrics);
  };

  const getOptionIcon = (value) => {
    switch(value) {
      case 'Power':
        return <Zap size={20} />;
      case 'HR':
        return <Heart size={20} />;
      case 'Both':
        return (
          <Box sx={{ display: 'flex', gap: 0.5 }}>
            <Zap size={20} />
            <Heart size={20} />
          </Box>
        );
      default:
        return null;
    }
  };

  const getOptionLabel = (value) => {
    switch(value) {
      case 'Power':
        return 'Power Based Training Only';
      case 'HR':
        return 'Heart Rate Based Training Only';
      case 'Both':
        return 'Use Both Power & Heart Rate';
      default:
        return '';
    }
  };

  return (
    <Box>
      <Typography 
        variant="subtitle1" 
        sx={{ 
          mb: 2,
          fontWeight: 500,
          color: 'text.primary'
        }}
      >
        Training Method
      </Typography>

      <RadioGroup
        value={radioValue}
        onChange={handleRadioChange}
        sx={{ width: '100%' }}
      >
        {['Power', 'HR', 'Both'].map((value) => (
          <Paper
            key={value}
            elevation={0}
            sx={{
              mb: 1,
              borderRadius: 2,
              border: '2px solid',
              borderColor: radioValue === value ? 'primary.main' : 'divider',
              backgroundColor: radioValue === value ? 'primary.main' : 'background.paper',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                borderColor: 'primary.main',
                backgroundColor: radioValue === value ? 'primary.dark' : 'action.hover',
              }
            }}
          >
            <FormControlLabel
              value={value}
              disabled={!isEditing}
              control={
                <Radio 
                  sx={{
                    color: radioValue === value ? 'primary.contrastText' : 'text.primary',
                    '&.Mui-checked': {
                      color: 'primary.contrastText',
                    }
                  }}
                />
              }
              label={
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1.5,
                  color: radioValue === value ? 'primary.contrastText' : 'text.primary',
                }}>
                  {getOptionIcon(value)}
                  <Typography sx={{ 
                    fontWeight: radioValue === value ? 600 : 400,
                  }}>
                    {getOptionLabel(value)}
                  </Typography>
                </Box>
              }
              sx={{
                mx: 0,
                px: 2,
                py: 1.5,
                width: '100%',
              }}
            />
          </Paper>
        ))}
      </RadioGroup>

      {!isEditing && radioValue === 'Both' && (
        <Alert 
          severity="info"
          icon={<Info size={20} />}
          sx={{ 
            mt: 2,
            borderRadius: 2,
            '& .MuiAlert-message': {
              fontSize: '0.875rem'
            }
          }}
        >
          You'll receive separate workouts for both Power-based and Heart Rate-based training.
        </Alert>
      )}
    </Box>
  );
};

export default CyclingMetrics;
